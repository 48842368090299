import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR } from '../../util/types';

import css from './OrderBreakdown.module.css';
import { bookingDiscountType } from '../../marketPlace-custom-config';
function convertDaysToMonthAndDays(days) {
  const months = Math.floor(days / 30);
  const remainingDays = days % 30;

  let result = '';
  if (months > 0) {
    result += months + ' month';
    if (months > 1) {
      result += 's'; // Pluralize "month" if necessary
    }
    result += ' ';
  }
  if (remainingDays > 0) {
    result += remainingDays + ' day';
    if (remainingDays > 1) {
      result += 's'; // Pluralize "day" if necessary
    }
  }

  return result;
}

const LineItemBasePriceMaybe = props => {
  const { lineItems, code, intl, disCountType, daysDifference } = props;
  const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;
  const translationKey = isNightly
    ? 'OrderBreakdown.baseUnitNight'
    : isDaily
    ? 'OrderBreakdown.baseUnitDay'
    : isHourly
    ? 'OrderBreakdown.baseUnitHour'
    : 'OrderBreakdown.baseUnitQuantity';
  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;
  const month = convertDaysToMonthAndDays(parseInt(quantity));
  const isOneMonth = parseInt(quantity) > 30;
  const greaterThanthirty = quantity === '30' ? 1 : Math.ceil(parseInt(quantity) / 30);

  // console.log(4444, unitPrice);
  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
        {/* {isOneMonth && <> ({month}) </>} */}
      </span>
      {/* <span className={css.itemLabel}>
        {disCountType === 'upfrontDiscount' ? (
          <>
            {' '}
      
            {`${unitPrice} x ${quantity} days`}
          </>
        ) : disCountType === 'MonthlySubscription' ? (
       
          <>
            {daysDifference > 30
              ? `${unitPrice} x ${quantity} M`
              : `${unitPrice} x ${quantity} days`}
          </>
        ) : (
          `${unitPrice} x ${quantity} days`
        )}
      </span> */}
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
