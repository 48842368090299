import moment from 'moment';

export const Months = [
  { label: 'January', key: 'jan' },
  { label: 'February', key: 'feb' },
  { label: 'March', key: 'mar' },
  { label: 'April', key: 'apr' },
  { label: 'May', key: 'may' },
  { label: 'June', key: 'june' },
  { label: 'July', key: 'july' },
  { label: 'August', key: 'aug' },
  { label: 'September', key: 'sep' },
  { label: 'October', key: 'oct' },
  { label: 'November', key: 'nov' },
  { label: 'December', key: 'dec' },
];
export const bookingDiscountType = {
  monthlySubscription: 'MonthlySubscription',
  upfrontDiscount: 'upfrontDiscount',
};
// const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

//  export function  getNextMonth  (currentMonthName,activePlanMonths) {
//     const monthsArray = activePlanMonths;
//     const currentMonth =currentMonthName;
//     const currentYear = moment().format('YYYY');

//     // Find index of current month in the monthsArray
//     const currentIndex = monthsArray?.indexOf(currentMonth);

//     // Iterate over the monthsArray starting from the index of current month
//     for (let i = currentIndex + 1; i < monthsArray?.length; i++) {
//       const nextMonth = monthsArray[i];
//       const nextMonthDate = moment(`${nextMonth} ${currentYear}`, 'MMM YYYY');

//       // If the next month is in the future, return its name
//       if (nextMonthDate.isAfter(moment())) {
//         return nextMonthDate.format('MMMM'); // Full month name
//       }
//     }

//     // If no future month found in this year's array, return null or any other desired value
//     return null;
//   };
