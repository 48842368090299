import moment from "moment";

// export function getNextMonth(currentMonthName, activePlanMonths) {
//   const monthsArray = activePlanMonths;
//   const currentMonth = currentMonthName;
//   const currentYear = moment().format('YYYY');
//   const nextYear = new Date().getFullYear() + 1;
//   // Find index of current month in the monthsArray
//   const currentIndex = monthsArray?.indexOf(currentMonth);
//       console.log(666, currentMonthName, activePlanMonths);

//   // Iterate over the monthsArray starting from the index of current month
//   if (currentIndex === -1) {
//    for (let i = currentIndex + 1; i < monthsArray?.length; i++) {
//      const nextMonth = monthsArray[i];
//      const nextMonthDate = moment(`${nextMonth} ${nextYear}`, 'MMM YYYY');

//      // If the next month is in the future, return its name
//      if (nextMonthDate.isAfter(moment())) {
//        return nextMonthDate.format('MMMM'); // Full month name
//      }
//    }
//   } else {
//     for (let i = currentIndex + 1; i < monthsArray?.length; i++) {
//       const nextMonth = monthsArray[i];
//       const nextMonthDate = moment(`${nextMonth} ${currentYear}`, 'MMM YYYY');

//       // If the next month is in the future, return its name
//       if (nextMonthDate.isAfter(moment())) {
//         return nextMonthDate.format('MMMM'); // Full month name
//       }
//     }
//   }
  

//   // If no future month found in this year's array, return null or any other desired value
//   return null;
// }
export function getNextMonth(currentMonthName, activePlanMonths) {
  const monthsArray = activePlanMonths;
  const currentMonth = currentMonthName;
  const currentYear = moment().format('YYYY');
  const nextYear = new Date().getFullYear() + 1;

  // Find index of current month in the monthsArray
  const currentIndex = monthsArray?.indexOf(currentMonth);

  // Iterate over the monthsArray starting from the index of current month
  if (currentIndex !== -1) {
    for (let i = currentIndex + 1; i < monthsArray?.length; i++) {
      const nextMonth = monthsArray[i];
      const nextMonthDate = moment(`${nextMonth} ${currentYear}`, 'MMM YYYY');

      // If the next month is in the future, return its name
      if (nextMonthDate.isAfter(moment())) {
        return nextMonthDate.format('MMMM'); // Full month name of the next month in the current year
      }
    }
  }

  // If no future month found in this year's array, return the first date of the next year for the first active plan month
  if (monthsArray && monthsArray.length > 0) {
    const firstActiveMonthNextYear = moment(`${monthsArray[0]} ${nextYear}`, 'MMM YYYY');
    return firstActiveMonthNextYear.format('MMMM'); // Full month name of the first active plan month in the next year
  }

  // If active plan months array is empty or no future month found, return null or any other desired value
  return null;
}
